/* Fields to be excluded or stringified when being logged */
export const LOGGLY_SETTINGS = {
  EXCLUDE_IF_EMPTY: ['statusText'],
  KEYS_TO_EXCLUDE: [
    'X-EBX-AccessToken',
    'X-EBX-AuthToken',
    'X-EBX-ClientAccessToken',
    'X-EBX-IdToken',
    'X-EBX-ImpersonateToken',
    'X-EBX-SubscriberPreferencesToken',
    'password',
    'oldPassword',
    'newPassword',
  ],
  KEYS_TO_STRINGIFY: ['dataJSON', 'mediaItem'],
};

/* Errors not to send to Sentry */
export const SENTRY_IGNORED_ERRORS = [
  {
    typeError: true,
    filename: 'react-dom.production.min.js',
    message: 'e is null',
  },
  {
    typeError: true,
    filename: 'insertTextIntoContentState.js',
    message: "Cannot read property 'length' of null",
  },
  {
    message:
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  },
  {
    message: 'HTTP Status 0',
  },
  {
    message: "Cannot read property 'getIn' of undefined",
  },
  {
    message: "Cannot read property 'getCharacterList' of undefined",
  },
  {
    message: "Cannot read property 'getEntityAt' of undefined",
  },
  {
    message: "Cannot read property 'getLength' of undefined",
  },
  {
    message: "Cannot read property 'nodeType' of null",
  },
  {
    message: "Cannot read property 'update' of null",
  },
  {
    message: 'y.getBlockTree(...) is undefined',
  },
  {
    message: 'EBX:INTERNAL - current property has changed',
  },
  {
    message: 'ResizeObserver loop limit exceeded',
  },
  {
    message:
      'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
  },
];
