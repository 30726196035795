import PropTypes from 'prop-types';

import LogoImage from 'components/subscribe/property/LogoImage';
import TitleDescription from 'components/subscribe/property/TitleDescription';

function PageHeader({ branding }) {
  return (
    <>
      <LogoImage logoImageURL={branding.logoImageURL} />
      <TitleDescription branding={branding} />
    </>
  );
}

PageHeader.propTypes = {
  branding: PropTypes.shape({
    logoImageURL: PropTypes.string,
    titleCopy: PropTypes.string,
    descriptionCopy: PropTypes.string,
  }).isRequired,
};

export default PageHeader;
