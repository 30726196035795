/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */
/* global mixpanel */

import { mandatory } from 'common/validation';

export { track };

/**
 * track
 * @param {{
 *  eventName: string;
 *  trackingParams?: object;
 *  options?: object;
 * }} args
 */
const track = ({
  eventName = mandatory('eventName'),
  trackingParams = {},
} = {}) => {
  if (typeof mixpanel !== 'undefined') {
    const distinctId = 'newsletter-reader';
    mixpanel.people.set({
      $distinct_id: distinctId,
    });
    mixpanel.identify(distinctId);
    mixpanel.track(eventName, trackingParams);
  } else {
    console.log(
      JSON.stringify({
        eventName,
        trackingParams,
      })
    );
  }
};
