/* eslint react-hooks/exhaustive-deps: "off" */

import * as validator from 'email-validator';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as string from 'common/string';
import * as tracker from 'common/tracker';
import useQuery from 'hooks/useQuery';

import './Base-Subscribe.css';
import './Embed-Subscribe.css';

const Name = ({ disabled, label, onChange, onKeyPress, value }) => (
  <div className="d-block w-100 subscribe__property__content__firstname">
    <div className="subscribe__property__content__label">{label}</div>
    <FormControl
      className="subscribe__property__content__input"
      type="text"
      value={value}
      disabled={disabled}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  </div>
);

Name.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const Subscribe = ({ branding }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isSigningUp, setSigningUp] = useState(false);
  const [isSignupComplete, setSignupComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { entityKey, campaignName } = useParams();
  const queryParams = useQuery();
  const isEmbeddedMode = queryParams.get('embed') !== null;

  const pageInstanceId = useRef(0);

  useEffect(() => {
    /* Generate random nine-digit number starting with non-zero digit */
    pageInstanceId.current = Math.floor(100000000 + Math.random() * 900000000);
    if (branding) {
      tracker.track({
        eventName: 'Display Subscription Page',
        trackingParams: {
          'Subscription page displayed to reader': window.location.href,
          '# campaigns offered': 1,
          'Page instance ID': pageInstanceId.current,
          'Subscription Page': 'Campaign',
        },
      });
    }
  }, []);

  if (!branding) {
    return null;
  }

  const showPrivacyPolicy =
    branding.isPrivacyPolicyEnabled && branding.privacyPolicyURL;
  let redirectAfterSubmit = '';
  if (isEmbeddedMode) {
    redirectAfterSubmit = branding.isDoubleOptInEnabled
      ? `/verify/${campaignName}/${entityKey}/${emailAddress}?embed`
      : `/success/${campaignName}/${entityKey}/${emailAddress}?embed`;
  } else {
    redirectAfterSubmit = branding.isDoubleOptInEnabled
      ? `/verify/${campaignName}/${entityKey}/${emailAddress}`
      : `/success/${campaignName}/${entityKey}/${emailAddress}`;
  }

  const handleChange = event => {
    setEmailAddress(event.target.value);
    setErrorMessage(null);
  };

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
    setErrorMessage(null);
  };

  const handleKeypress = event => {
    setErrorMessage(null);
    if (event.charCode === 13) {
      handleSubmit();
    }
  };

  const handleLastNameChange = event => {
    setLastName(event.target.value);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    if (!validator.validate(emailAddress.trim())) {
      setErrorMessage(branding.subscribeEmailAddressError);
      return;
    }
    setSigningUp(true);
    try {
      await API.postSubscribe({
        campaignKey: entityKey,
        emailAddress: emailAddress.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      });
      tracker.track({
        eventName: 'Reader Subscribe',
        trackingParams: {
          '# campaigns offered': 1,
          '# campaigns subscribed to': 1,
          'Page instance ID': pageInstanceId.current,
          'Subscription Page': 'Campaign',
        },
      });
      setSignupComplete(true);
      setSigningUp(false);
    } catch (error) {
      console.log(error);
      let errorMsg = errors.getErrorMessage(error);
      if (errorMsg === 'Internal Server Error') {
        errorMsg = branding.subscribeGeneralError;
      }
      setErrorMessage(errorMsg);
      setSigningUp(false);
    }
  };

  const SignupButton = () => (
    <Button
      className="subscribe__content__button ebx-btn-lg"
      type="button"
      disabled={isSigningUp}
      onClick={handleSubmit}
    >
      {!isSigningUp ? (
        branding.signupButtonLabel
      ) : (
        <div>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
        </div>
      )}
    </Button>
  );

  const embeddedClass = isEmbeddedMode ? 'embedded' : '';

  return (
    <>
      {isSignupComplete && <Redirect to={redirectAfterSubmit} />}
      <div className={`subscribe__page client__specific ${embeddedClass}`}>
        {branding.logoImageURL === '' ? null : (
          <Row className="subscribe__header w-100 d-flex align-items-center m-0">
            <Col className="text-center">
              <img
                className="subscribe__banner subscribe__logo__image"
                alt="Banner"
              />
            </Col>
          </Row>
        )}

        <Row className="m-0 justify-content-center subscribe__signup--padding">
          <div className="subscribe__signup__container">
            <div>
              {branding.headerImageURL === '' ? (
                <div className="subscribe__header__text">
                  <span>{branding.campaignName}</span>
                  <hr className="subscribe__header__divider" />
                </div>
              ) : (
                <div className="subscribe__signup__header m-auto">
                  <img
                    className="subscribe__signup__header__image"
                    alt="Header"
                  />
                </div>
              )}

              <div className="subscribe__signup__content m-auto d-flex align-items-center">
                <div className="subscribe__signup__content__container">
                  <div className="subscribe__content__title">
                    <span>
                      {string.replacePlaceholders(branding.titleCopy, branding)}
                    </span>
                  </div>
                  <div className="subscribe__content__description subscribe__content__signup__description">
                    {branding.bodyCopy}
                  </div>
                  <div>
                    {branding?.isFirstNameLastNameEnabled && (
                      <div className="subscribe__signup__content__container d-flex flex-column flex-md-row">
                        <Name
                          disabled={isSigningUp}
                          label={branding?.firstNameLabel ?? 'First name'}
                          onChange={handleFirstNameChange}
                          onKeyPress={handleKeypress}
                          value={firstName}
                        />
                        <Name
                          disabled={isSigningUp}
                          label={branding?.lastNameLabel ?? 'Last name'}
                          onChange={handleLastNameChange}
                          onKeyPress={handleKeypress}
                          value={lastName}
                        />
                      </div>
                    )}
                    <div className="subscribe__content__email">
                      {branding.emailInputLabel}{' '}
                      <span className="mandatory">*</span>
                    </div>
                    <div>
                      <InputGroup className="subscribe__input__group">
                        <FormControl
                          className="subscribe__content__input"
                          type="email"
                          value={emailAddress}
                          disabled={isSigningUp}
                          onChange={handleChange}
                          onKeyPress={handleKeypress}
                        />
                        <SignupButton />
                      </InputGroup>
                    </div>
                    {errorMessage && (
                      <div className="subscribe__content__error">
                        {errorMessage}
                      </div>
                    )}
                    {showPrivacyPolicy && (
                      <div className="subscribe__content__privacy mt-3">
                        {branding.signupFooterText} -{' '}
                        <a href={`${branding.privacyPolicyURL}`}>
                          {branding.privacyPolicyLinkText || 'Privacy Policy'}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

Subscribe.propTypes = {
  branding: PropTypes.shape({
    bodyCopy: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    emailInputLabel: PropTypes.string,
    firstNameLabel: PropTypes.string,
    headerImageURL: PropTypes.string,
    isDoubleOptInEnabled: PropTypes.bool,
    logoImageURL: PropTypes.string,
    isFirstNameLastNameEnabled: PropTypes.bool,
    isPrivacyPolicyEnabled: PropTypes.bool,
    lastNameLabel: PropTypes.string,
    privacyPolicyLinkText: PropTypes.string,
    privacyPolicyURL: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    signupButtonLabel: PropTypes.string.isRequired,
    signupFooterText: PropTypes.string.isRequired,
    titleCopy: PropTypes.string.isRequired,
    subscribeGeneralError: PropTypes.string.isRequired,
    subscribeEmailAddressError: PropTypes.string.isRequired,
  }),
};

Subscribe.defaultProps = {
  branding: null,
};

export default Subscribe;
