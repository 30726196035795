import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import * as string from 'common/string';

function TitleDescription({ branding }) {
  return (
    <Row className="flex-column m-0 multi__campaign__content">
      <div className="subscribe__content__title">
        {string.replacePlaceholders(branding.titleCopy, branding)}
      </div>
      <div className="subscribe__property__content__description">
        {branding.descriptionCopy}
      </div>
    </Row>
  );
}

TitleDescription.propTypes = {
  branding: PropTypes.shape({
    logoImageURL: PropTypes.string,
    titleCopy: PropTypes.string,
    descriptionCopy: PropTypes.string,
  }).isRequired,
};

export default TitleDescription;
