import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function LogoImage({ logoImageURL }) {
  if (!logoImageURL) {
    return null;
  }

  return (
    <Row className="subscribe__header w-100 d-flex align-items-center m-0">
      <Col className="text-center">
        <img className="subscribe__banner" alt="Banner" />
      </Col>
    </Row>
  );
}

LogoImage.propTypes = {
  logoImageURL: PropTypes.string.isRequired,
};

export default LogoImage;
