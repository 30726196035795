import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';

import * as API from 'api/api';
import './Base-Subscribe.css';

import useQuery from 'hooks/useQuery';

const Requested = () => {
  const { entityKey, userEmail } = useParams();
  const [isError, setError] = useState(false);

  const query = useQuery();
  const customFields = [...query.keys()]
    .map(key => [key, query.getAll(key)])
    .reduce(
      (acc, [key, values]) => ({
        ...acc,
        // Convert to a single value for singleton arrays
        [key]: values.length === 1 ? values[0] : values,
      }),
      {}
    );

  useEffect(() => {
    async function confirmSubscribe() {
      try {
        const redirectURL = await API.postConfirmSubscribe({
          campaignKey: entityKey,
          emailAddress: userEmail.trim(),
          customFields,
        });
        window.location.href = redirectURL;
      } catch (error) {
        console.log(error);
        setError(true);
      }
    }

    confirmSubscribe();
  }, [entityKey, userEmail, customFields]);

  return (
    <>
      {isError && <Redirect push to="/error" />}

      <div className="d-flex justify-content-center subscribe__page flex-wrap">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          className="mr-2 my-auto"
        />
      </div>
    </>
  );
};

export default Requested;
