import { Spinner } from 'react-bootstrap';

function Loading() {
  return (
    <div className="loading--height d-flex align-items-center justify-content-center w-100 mt-5">
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;
