/* eslint no-param-reassign: "off" */

import axios from 'axios';

import * as errors from 'common/errors';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, apiBaseURL } from 'api/settings';

export default async function postConfirmSubscribe({
  campaignKey = mandatory('campaignKey'),
  emailAddress = mandatory('emailAddress'),
  customFields,
}) {
  let campaignURN = campaignKey;
  if (campaignKey.split(':').length === 1) {
    campaignURN = `urn:newsletter:campaign:${campaignKey}`;
  }

  const config = {
    url: `${apiBaseURL()}/public/campaigns/subscribe/confirm`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      campaignURN,
      email: btoa(emailAddress),
      customFields,
    }),
  };

  try {
    const response = await axios(config);
    return response.headers.location;
  } catch (error) {
    const apiError = await errors.handleAPIError({
      originalError: error,
      errorLocation: 'api/postConfirmSubscribe',
      args: { campaignURN, emailAddress },
    });
    throw apiError;
  }
}
