import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import ErrorPage from 'components/ErrorPage';
import Requested from 'components/subscribe/Requested';
import Subscribe from 'components/subscribe/Subscribe';
import Success from 'components/subscribe/Success';
import Verify from 'components/subscribe/Verify';
import AccessPreferences from 'components/subscribe/property/AccessPreferences';
import AccessPreferencesSent from 'components/subscribe/property/AccessPreferencesSent';
import ManagePreferences from 'components/subscribe/property/ManagePreferences';
import PropertySubscribe from 'components/subscribe/property/PropertySubscribe';
import Unsubscribe from 'components/unsubscribe/Unsubscribe';

import defaultBrandingData from 'assets/json/branding.json';
import { PAGES, REDIRECT_HOME } from 'common/constants';
import * as css from 'common/css';
import * as environment from 'common/environment';
import { isInIframe } from 'common/utility';

const fallback = <ErrorPage />;

const Loader = ({ children }) => {
  let brandingData;
  if (
    window.EBX.branding === '<$- BRANDING_JSON $>' &&
    environment.getHostname() === 'dev'
  ) {
    brandingData = defaultBrandingData;
  } else {
    brandingData = JSON.parse(window.EBX.branding);
    if (brandingData.error) {
      return fallback;
    }
  }
  css.setBranding(brandingData);

  return React.Children.map(children, child =>
    React.cloneElement(child, {
      branding: brandingData,
    })
  );
};

Loader.propTypes = {
  children: PropTypes.node.isRequired,
};

const WrappedSubscribe = () => (
  <Loader>
    <Subscribe />
  </Loader>
);

const WrappedSuccess = () => (
  <Loader>
    <Success />
  </Loader>
);

const WrappedUnsubscribe = () => (
  <Loader>
    <Unsubscribe />
  </Loader>
);

const WrappedVerify = () => (
  <Loader>
    <Verify />
  </Loader>
);

const WrappedPropertySubscribe = () => (
  <Loader>
    <PropertySubscribe />
  </Loader>
);

const WrappedManagePreferences = () => (
  <Loader>
    <ManagePreferences />
  </Loader>
);

const WrappedManagePreferencesSuccess = () => (
  <Loader>
    <ManagePreferences success={true} />
  </Loader>
);

const WrappedAccessPreferences = () => (
  <Loader>
    <AccessPreferences />
  </Loader>
);

const WrappedAccessPreferencesSent = () => (
  <Loader>
    <AccessPreferencesSent />
  </Loader>
);

const HomePage = () => {
  // If we are not in an iframe or if branding undefined redirect
  if (!isInIframe() || window?.EBX?.branding == null) {
    window.location.href = REDIRECT_HOME;
    return null;
  }

  const brandingData = JSON.parse(window.EBX.branding);
  console.log(brandingData?.page);

  switch (brandingData?.page) {
    case PAGES.SUBSCRIBE:
      return <WrappedSubscribe />;
    case PAGES.UNSUBSCRIBE:
      return <WrappedUnsubscribe />;
    case PAGES.PROPERTY_SUBSCRIBE:
      return <WrappedPropertySubscribe />;
    case PAGES.VERIFY:
      return <WrappedVerify />;
    case PAGES.SUCCESS:
      return <WrappedSuccess />;
    case PAGES.MANAGE_PREFERENCES:
      return <WrappedManagePreferences />;
    case PAGES.MANAGE_PREFERENCES_SUCCESS:
      return <WrappedManagePreferencesSuccess />;
    case PAGES.ACCESS_PREFERENCES:
      return <WrappedAccessPreferences />;
    case PAGES.ACCESS_PREFERENCES_SENT:
      return <WrappedAccessPreferencesSent />;
    default:
      throw new Error('Page passed in branding does not match known pages');
  }
};

const Routing = () => (
  <Sentry.ErrorBoundary fallback={fallback}>
    <Router>
      <Switch>
        <Route
          exact
          path="/accesspreferencessent/:entityKey"
          component={WrappedAccessPreferencesSent}
        />
        <Route
          exact
          path="/accesspreferences/:entityKey"
          component={WrappedAccessPreferences}
        />
        <Route
          exact
          path="/manage/:entityKey/success"
          component={WrappedManagePreferencesSuccess}
        />
        <Route
          exact
          path="/manage/:entityKey"
          component={WrappedManagePreferences}
        />
        <Route
          exact
          path="/subscribe/:campaignName/:entityKey"
          component={WrappedSubscribe}
        />
        <Route
          exact
          path="/subscribe/:entityKey"
          component={WrappedPropertySubscribe}
        />
        <Route
          exact
          path="/verify/:campaignName/:entityKey/:userEmail"
          component={WrappedVerify}
        />
        <Route
          exact
          path="/requested/:campaignName/:entityKey/:userEmail"
          component={Requested}
        />
        <Route
          exact
          path="/success/:campaignName/:entityKey/:userEmail"
          component={WrappedSuccess}
        />
        <Route
          exact
          path="/unsubscribe/:entityKey"
          component={WrappedUnsubscribe}
        />
        <Route exact path="/error" component={ErrorPage} />
        <Route path="*" component={HomePage} />
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>
);

export default Routing;
