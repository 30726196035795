import { getHostname } from 'common/environment';

export const API_TIMEOUTS = {
  XXS: 5000, // 5 seconds
  XS: 15000, // 15 seconds
  S: 60000, // 1 minute
  M: 120000, // 2 minutes
  LM: 180000, // 3 minutes
  L: 240000, // 4 minutes
  XL: 360000, // 6 minutes
  XXL: 720000, // 12 minutes
  XXXL: 1500000, // 25 minutes
};

export const API_BASE_URLS = {
  // dev: 'http://localhost:8080',
  dev: 'https://campaignapi-stage.service.echobox.com',
  stage: 'https://campaignapi-stage.service.echobox.com',
  live: 'https://campaignapi.service.echobox.com',
};

export const apiBaseURL = (version = '1') => {
  const environmentName = getHostname();
  let apiURL = `${API_BASE_URLS[environmentName]}`;
  if (version !== '') {
    apiURL = `${apiURL}/v${version}`;
  }
  return apiURL;
};
