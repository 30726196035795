import { Form } from 'react-bootstrap';

import PropTypes from 'prop-types';

import './Campaign.css';

const Checkbox = ({ className, handleChange, id, isSelected, labelName }) => (
  <div className={`campaign__box ${className}`}>
    <Form.Check
      className="item-click d-flex align-items-center"
      name={id}
      id={id}
      custom
    >
      <Form.Check.Input
        type="checkbox"
        checked={isSelected}
        onChange={e => handleChange(e.target.id)}
        className="input-box"
      />
      <Form.Check.Label className="d-flex align-items-center">
        <span className="campaign__text">{labelName}</span>
      </Form.Check.Label>
    </Form.Check>
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  labelName: PropTypes.string,
};

Checkbox.defaultProps = {
  className: '',
  handleChange: null,
  id: null,
  isSelected: null,
  labelName: null,
};

export default Checkbox;
