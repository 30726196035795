import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Loading from 'components/Loading';
import CampaignList from 'components/subscribe/property/CampaignList';
import Checkbox from 'components/subscribe/property/Checkbox';
import InvalidLink from 'components/subscribe/property/InvalidLink';
import PageHeader from 'components/subscribe/property/PageHeader';
import UpdatePreferences from 'components/subscribe/property/UpdatePreferences';

import { ReactComponent as Tick } from 'assets/svg/tick.svg';

import * as API from 'api/api';
import * as logger from 'common/logger';
import useInterval from 'hooks/useInterval';
import useQuery from 'hooks/useQuery';

import './ManagePreferences.css';

const JWT_SESSION_STORAGE_KEY_NAME = 'subscriberPreferencesToken';
const CAMPAIGN_SESSION_STORAGE_KEY_NAME = 'campaignURN';
const EMAIL_SESSION_STORAGE_KEY_NAME = 'email';

const isValidRedirectURL = redirectURL => redirectURL && redirectURL !== '';

function ManagePreferences({ branding, success }) {
  const { entityKey: propertyURN } = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const isPreview = branding?.page ?? false;

  let email = query.get('email');
  if (email) {
    sessionStorage.setItem(EMAIL_SESSION_STORAGE_KEY_NAME, email);
  } else {
    email = sessionStorage.getItem(EMAIL_SESSION_STORAGE_KEY_NAME);
  }
  /* Set default email address if rendering a preview */
  if (isPreview) {
    email = branding?.emailAddress ?? '';
  }

  let campaignKey = query.get('campaignURN');
  if (campaignKey) {
    sessionStorage.setItem(CAMPAIGN_SESSION_STORAGE_KEY_NAME, campaignKey);
  } else {
    campaignKey = sessionStorage.getItem(CAMPAIGN_SESSION_STORAGE_KEY_NAME);
  }

  let token;
  token = query.get('token');
  if (token) {
    /* Store the JWT for later use */
    sessionStorage.setItem(JWT_SESSION_STORAGE_KEY_NAME, token);
    /* Remove the JWT from the URL */
    history.replace(location.pathname);
  } else {
    /* Retrieve the JWT previously stored */
    token = sessionStorage.getItem(JWT_SESSION_STORAGE_KEY_NAME);
  }

  const [isLoading, setLoading] = useState(true);
  const [isTokenValid, setTokenValid] = useState(true);

  const [campaignList, setCampaignList] = useState(null);
  const originalCampaignList = useRef(null);

  const [isCountingDown, setCountingDown] = useState(false);
  const [timeRemainingSecs, setTimeRemainingSecs] = useState(3);

  useInterval(
    // Callback - code to be executed each interval
    () => {
      if (timeRemainingSecs > 0) {
        setTimeRemainingSecs(timeRemainingSecs - 1);
      } else {
        setCountingDown(false);
        handleRedirect();
      }
    },
    // Interval - return null to stop the interval when the countdown reaches zero
    isCountingDown ? 1000 : null
  );

  const handleRedirect = () => {
    window.location.href = branding.redirectURL;
  };

  /* Call the relevant endpoint to get the reader's subscription data */
  useEffect(() => {
    const fetchCampaignList = async () => {
      let initialCampaignList;
      try {
        initialCampaignList = await API.getSubscriberPreferences({
          jwt: token,
        });
        // Re-order initial campaign list using ordered campaigns in branding
        const campaignURNIndexMap = new Map(
          branding.orderedCampaigns.map((campaign, index) => [
            campaign.campaignURN,
            index,
          ])
        );
        initialCampaignList.sort(
          (a, b) =>
            campaignURNIndexMap.get(a.urn) - campaignURNIndexMap.get(b.urn)
        );
        originalCampaignList.current = initialCampaignList.map(campaign => ({
          isSelected: campaign.isSubscribed,
          campaignURN: campaign.urn,
          name: campaign.name,
        }));
        setCampaignList(originalCampaignList.current);
      } catch (error) {
        /* Assume the endpoint call failed because of an invalid token */
        setErrorMessage('Failed to get preferences');
        setTokenValid(false);
      } finally {
        setLoading(false);
      }
    };
    if (token !== null) {
      /* If a valid token exists, fetch the reader's current subscriptions */
      fetchCampaignList();
    } else if (isPreview && branding) {
      /* Just use the default list of campaigns when rendering a preview */
      originalCampaignList.current = branding.orderedCampaigns.map(
        campaign => ({
          campaignURN: campaign.urn,
          isSelected: false,
          name: campaign.name,
        })
      );
      setCampaignList(originalCampaignList.current);
    }
  }, [branding, isPreview, token]);

  const changesExist = () => {
    if (!originalCampaignList.current || !campaignList) {
      return false;
    }
    const originalSelectedCampaigns = originalCampaignList.current
      .filter(campaign => campaign.isSelected)
      .map(campaign => campaign.campaignURN);
    const currentSelectedCampaigns = campaignList
      .filter(campaign => campaign.isSelected)
      .map(campaign => campaign.campaignURN);
    return (
      originalSelectedCampaigns.length !== currentSelectedCampaigns.length ||
      originalSelectedCampaigns.some(
        (campaignURN, index) => campaignURN !== currentSelectedCampaigns[index]
      )
    );
  };

  const FORM_STATES = {
    PENDING: 'PENDING',
    UPDATED: 'UPDATED',
    CANCELLED: 'CANCELLED',
  };

  const [isBusy, setBusy] = useState(false);
  const [formState, setFormState] = useState(FORM_STATES.PENDING);
  const [errorMessage, setErrorMessage] = useState(null);

  const isCancelDisabled = useRef(
    isBusy ||
      campaignList?.filter(campaign => campaign.isSelected).length === 0 ||
      isPreview
  );
  const isUpdateDisabled =
    isBusy ||
    campaignList?.filter(campaign => campaign.isSelected).length === 0 ||
    !changesExist() ||
    isPreview;

  /* Render error page if the token is missing or invalid */
  /* except if we are rendering a preview with POST-provided branding data */
  if ((token === null || !isTokenValid) && !isPreview) {
    return (
      <InvalidLink
        branding={branding}
        propertyURN={propertyURN}
        campaignURN={campaignKey}
        emailAddress={email}
      />
    );
  }

  /* Render nothing while waiting for branding data */
  /* or while waiting for the default campaign list for a preview to be set */
  if (!branding || !campaignList) {
    return null;
  }

  /* Render spinner while waiting for endpoint call */
  if (isLoading && token !== null) {
    return <Loading />;
  }

  const handleCancel = async () => {
    logger.info('ManagePreferences:handleCancel');

    setBusy(true);
    try {
      await API.postSubscriberPreferences({
        jwt: token,
        unsubscribeFromAll: true,
      });
      setFormState(FORM_STATES.CANCELLED);
      if (isValidRedirectURL(branding.redirectURL)) {
        setCountingDown(true);
      }
    } catch (error) {
      setErrorMessage('Failed to update preferences');
      /* Assume the endpoint call failed because of an invalid token */
      setTokenValid(false);
    } finally {
      setBusy(false);
    }
  };

  const handleSelect = campaignURN => {
    logger.info(`ManagePreferences:handleSelect ${campaignURN}`);

    const newCampaignList = campaignList.map(campaign => {
      if (campaign.campaignURN === campaignURN) {
        const modifiedCampaign = {
          ...campaign,
          isSelected: !campaign.isSelected,
        };
        return modifiedCampaign;
      }
      return campaign;
    });
    setCampaignList(newCampaignList);
    setErrorMessage(null);
  };

  const handleAllSelect = () => {
    logger.info(`ManagePreferences:handleAllSelect`);
    const shouldSelectAll = campaignList.every(
      campaign => campaign.isSelected === true
    );
    const newCampaignList = campaignList.map(campaign => ({
      ...campaign,
      isSelected: !shouldSelectAll,
    }));
    setCampaignList(newCampaignList);
    setErrorMessage(null);
  };

  const handleUpdate = async () => {
    logger.info('ManagePreferences:handleUpdate');

    const subscribeTo = campaignList
      .filter(campaign => campaign.isSelected)
      .map(campaign => campaign.campaignURN);
    const unsubscribeFrom = campaignList
      .filter(campaign => !campaign.isSelected)
      .map(campaign => campaign.campaignURN);

    setBusy(true);
    try {
      await API.postSubscriberPreferences({
        jwt: token,
        subscribeTo,
        unsubscribeFrom,
      });
      setFormState(FORM_STATES.UPDATED);
      if (isValidRedirectURL(branding.redirectURL)) {
        setCountingDown(true);
      }
    } catch (error) {
      setErrorMessage('Failed to update preferences');
      /* Assume the endpoint call failed because of an invalid token */
      setTokenValid(false);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="subscribe__page client__specific d-flex flex-column">
      <PageHeader branding={branding} />
      <Checkbox
        className="all_campaigns"
        labelName={branding.selectAllNewslettersButtonText ?? 'All newsletters'}
        isSelected={campaignList.every(
          campaign => campaign.isSelected === true
        )}
        handleChange={handleAllSelect}
        id={branding.selectAllNewslettersButtonText ?? 'All newsletters'}
      />
      <CampaignList
        campaignList={campaignList}
        errorMessage={errorMessage}
        handleSelection={handleSelect}
      />
      {formState === FORM_STATES.PENDING && !success && (
        <UpdatePreferences
          branding={branding}
          errorMessage={errorMessage}
          handleCancel={handleCancel}
          handleUpdate={handleUpdate}
          isBusy={isBusy}
          isCancelDisabled={isCancelDisabled.current}
          isUpdateDisabled={isUpdateDisabled}
        />
      )}
      {formState === FORM_STATES.UPDATED && (
        <Row className="subscribe__footer manage__preferences__footer ml-3">
          <div className="subscribe__variable__width">
            <Tick />
            <span className="p-2">{branding.preferencesUpdatedCopy}</span>
          </div>
        </Row>
      )}
      {formState === FORM_STATES.CANCELLED && (
        <Row className="subscribe__footer manage__preferences__footer ml-3">
          <div className="subscribe__variable__width">
            <Tick />
            <span className="p-2">{branding.unsubscribedFromAllCopy}</span>
          </div>
        </Row>
      )}
      {success && (
        <>
          <Row className="subscribe__footer manage__preferences__footer ml-3">
            <div className="subscribe__variable__width">
              <Tick />
              <span className="p-2">{branding.preferencesUpdatedCopy}</span>
            </div>
            <div className="subscribe__variable__width">
              <Tick />
              <span className="p-2">{branding.unsubscribedFromAllCopy}</span>
            </div>
          </Row>
        </>
      )}
    </div>
  );
}

ManagePreferences.propTypes = {
  branding: PropTypes.shape({
    logoImageURL: PropTypes.string,
    signupButtonLabel: PropTypes.string,
    emailInputLabel: PropTypes.string,
    helperText: PropTypes.string,
    titleCopy: PropTypes.string,
    descriptionCopy: PropTypes.string,
    confirmationTitleCopy: PropTypes.string,
    confirmationBodyCopy: PropTypes.string,
    redirectURL: PropTypes.string,
    redirectMessageCopy: PropTypes.string,
    preferencesUpdatedCopy: PropTypes.string,
    selectAllNewslettersButtonText: PropTypes.string,
    unsubscribedFromAllCopy: PropTypes.string,
    orderedCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        campaignURN: PropTypes.string,
        campaignName: PropTypes.string,
      })
    ),
    page: PropTypes.string,
    emailAddress: PropTypes.string,
    propertyPrivacyPolicyEnabled: PropTypes.bool,
    propertyPrivacyPolicyLink: PropTypes.string,
    propertyPrivacyPolicyText: PropTypes.string,
  }),
  success: PropTypes.bool,
};

ManagePreferences.defaultProps = {
  branding: null,
  success: false,
};

export default ManagePreferences;
