import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';

import * as API from 'api/api';
import * as errors from 'common/errors';
import useQuery from 'hooks/useQuery';

function AccessPreferences({ branding }) {
  const { entityKey: propertyURN } = useParams();
  const query = useQuery();
  const emailAddress = query.get('email');
  const campaignKey = query.get('campaignURN');

  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirectURL = `/accesspreferencessent/${propertyURN}?email=${emailAddress}`;

  const showPrivacyPolicy =
    branding?.propertyPrivacyPolicyEnabled &&
    branding?.propertyPrivacyPolicyLink;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.postAccessPreferences({
        campaignKey,
        emailAddress,
      });
      setComplete(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(errors.getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (isComplete) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <Row className="m-0 justify-content-center subscribe__signup--padding">
      <div className="access__preferences__container">
        <Row className="subscribe__header access__preferences__header w-100 d-flex align-items-center m-0">
          <Col className="text-center">
            <img className="subscribe__banner" alt="Banner" />
          </Col>
        </Row>
        <Row className="subscribe__header access__preferences__footer w-100 d-flex align-items-center m-0">
          <div className="subscribe__content__title w-100">
            {branding.accessHostedPageTitleCopy}
          </div>
          <div className="subscribe__content__description w-100 access_preferences__body">
            {branding.accessHostedPageBodyCopy}
          </div>
          <div className="m-0">
            <Button
              className="subscribe__content__button m-0 ebx-btn-lg"
              type="button"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {!isLoading ? (
                branding.accessHostedPageButtonText
              ) : (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                </div>
              )}
            </Button>
            {errorMessage && (
              <div className="subscribe__content__error">{errorMessage}</div>
            )}
            {showPrivacyPolicy && (
              <div className="subscribe__content__privacy mt-3">
                <a href={`${branding.propertyPrivacyPolicyLink}`}>
                  {branding.propertyPrivacyPolicyText || 'Privacy Policy'}
                </a>
              </div>
            )}
          </div>
        </Row>
      </div>
    </Row>
  );
}

AccessPreferences.propTypes = {
  branding: PropTypes.shape({
    accessHostedPageBodyCopy: PropTypes.string,
    accessHostedPageButtonText: PropTypes.string,
    accessHostedPageTitleCopy: PropTypes.string,
    logoImageURL: PropTypes.string,
    signupButtonLabel: PropTypes.string,
    emailInputLabel: PropTypes.string,
    helperText: PropTypes.string,
    titleCopy: PropTypes.string,
    descriptionCopy: PropTypes.string,
    confirmationTitleCopy: PropTypes.string,
    confirmationBodyCopy: PropTypes.string,
    redirectURL: PropTypes.string,
    redirectMessageCopy: PropTypes.string,
    preferencesUpdatedCopy: PropTypes.string,
    propertyPrivacyPolicyEnabled: PropTypes.bool,
    propertyPrivacyPolicyLink: PropTypes.string,
    propertyPrivacyPolicyText: PropTypes.string,
    unsubscribedFromAllCopy: PropTypes.string,
    orderedCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        campaignURN: PropTypes.string,
        campaignName: PropTypes.string,
      })
    ),
  }),
};

AccessPreferences.defaultProps = {
  branding: null,
};

export default AccessPreferences;
