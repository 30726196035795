/* eslint no-param-reassign: "off" */

import axios from 'axios';

import * as errors from 'common/errors';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, apiBaseURL } from 'api/settings';

export default async function postSubscriberPreferences({
  jwt = mandatory('jwt'),
  subscribeTo = [],
  unsubscribeFrom = [],
  unsubscribeFromAll = false,
}) {
  const config = {
    url: `${apiBaseURL()}/public/subscriberpreferencescenter`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: {
      'X-EBX-SubscriberPreferencesToken': jwt,
    },
    data: JSON.stringify({
      subscribeTo,
      unsubscribeFrom,
      unsubscribeFromAll,
    }),
  };

  try {
    const response = await axios(config);
    return response.data.campaigns;
  } catch (error) {
    const apiError = await errors.handleAPIError({
      originalError: error,
      errorLocation: 'api/postSubscriberPreferences',
    });
    throw apiError;
  }
}
