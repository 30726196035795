import reactStringReplace from 'react-string-replace';

export { errorGuid, replacePlaceholders, removeQueryParam };

function errorGuid() {
  let errorHash = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i += 1) {
    errorHash += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return errorHash;
}

function replacePlaceholders(string, variables, prefix = '', suffix = '') {
  let result = string;
  for (const variable in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, variable)) {
      result = reactStringReplace(
        result,
        `{${variable}}`,
        () => `${prefix}${variables[variable]}${suffix}`
      );
    }
  }

  // reactStringReplace returns an array
  // However, if it is never called result may still be a string
  const resultArray = result instanceof Array ? result : [result];

  return resultArray.join('');
}

// Removes the query param provided from the url
function removeQueryParam(url, param) {
  const urlObject = new URL(url);
  const urlSearchParams = urlObject?.searchParams;

  if (urlSearchParams) {
    urlSearchParams.delete(param);
  }

  return urlObject.toString();
}
