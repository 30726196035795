import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Campaign from 'components/subscribe/property/Campaign';

function CampaignList({ campaignList, errorMessage, handleSelection }) {
  return (
    <>
      {errorMessage && (
        <Row className="m-0 mb-4 subscribe__property__error">
          <Col xs={12}>
            <div className="subscribe__content__error">{errorMessage}</div>
          </Col>
        </Row>
      )}
      <Row className="m-0 mb-4 subscribe__property__campaigns">
        {campaignList.map(campaign => (
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            className="campaign-item"
            key={`col-${campaign.campaignURN}`}
          >
            <Campaign
              key={`campaign-${campaign.campaignURN}`}
              campaignURN={campaign.campaignURN}
              campaignName={campaign.name}
              isSelected={campaign.isSelected}
              handleSelection={handleSelection}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

CampaignList.propTypes = {
  campaignList: PropTypes.arrayOf(
    PropTypes.shape({
      campaignURN: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  errorMessage: PropTypes.string,
  handleSelection: PropTypes.func.isRequired,
};

CampaignList.defaultProps = {
  errorMessage: null,
};

export default CampaignList;
