/* eslint no-param-reassign: "off" */

import axios from 'axios';

import * as errors from 'common/errors';
import * as utility from 'common/utility';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, apiBaseURL } from 'api/settings';

interface PostSubscribeProps {
  campaignKey: string;
  emailAddress: string;
  firstName?: string;
  lastName?: string;
}

interface PostSubscribeParams {
  campaignURN: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

export default async function postSubscribe({
  campaignKey,
  emailAddress,
  firstName,
  lastName,
}: PostSubscribeProps) {
  if (utility.isUndefined(campaignKey)) {
    mandatory('campaignKey');
  }
  if (utility.isUndefined(emailAddress)) {
    mandatory('emailAddress');
  }

  let campaignURN = campaignKey;
  if (campaignKey.split(':').length === 1) {
    campaignURN = `urn:newsletter:campaign:${campaignKey}`;
  }
  const params: PostSubscribeParams = {
    campaignURN,
    email: emailAddress,
  };
  if (firstName !== '') {
    params.firstName = firstName;
  }
  if (lastName !== '') {
    params.lastName = lastName;
  }
  const config = {
    url: `${apiBaseURL()}/public/campaigns/subscribe`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(params),
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    const apiError = await errors.handleAPIError({
      // @ts-ignore
      originalError: error,
      // @ts-ignore
      errorLocation: 'api/postSubscribe',
      args: { campaignURN, emailAddress },
    });
    throw apiError;
  }
}
