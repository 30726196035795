import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import reactStringReplace from 'react-string-replace';

import useInterval from 'hooks/useInterval';

import * as string from 'common/string';

import '../subscribe/Base-Subscribe.css';

const Unsubscribe = ({ branding }) => {
  const hasRedirectURL = branding?.redirectURL && branding.redirectURL !== '';
  const isPreview = branding?.page;
  const [isCountingDown, setCountingDown] = useState(hasRedirectURL);
  const [timeRemainingSecs, setTimeRemainingSecs] = useState(15);

  useInterval(
    // Callback - code to be executed each interval
    () => {
      if (timeRemainingSecs > 0) {
        setTimeRemainingSecs(timeRemainingSecs - 1);
      } else {
        setCountingDown(false);
        handleRedirect();
      }
    },
    // Interval - return null to stop the interval when the countdown reaches zero
    isCountingDown ? 1000 : null
  );

  if (!branding) {
    return null;
  }

  const timeRemaining = () =>
    `${timeRemainingSecs} second${timeRemainingSecs !== 1 ? 's' : ''}`;

  const handleCancel = () => {
    setCountingDown(false);
  };

  const handleRedirect = () => {
    window.location.href = branding.redirectURL;
  };

  const unsubscribeTitleCopy = branding.unsubscribeTitleCopy
    ? string.replacePlaceholders(branding.unsubscribeTitleCopy, branding)
    : 'Unsubscribe successful';
  const unsubscribeBodyCopy = branding.unsubscribeBodyCopy
    ? string.replacePlaceholders(branding.unsubscribeBodyCopy, branding)
    : "You'll no longer receive emails from us about this campaign.";

  return (
    <>
      <div className="subscribe__page client__specific">
        {branding.logoImageURL === '' ? null : (
          <Row className="subscribe__header w-100 d-flex align-items-center m-0">
            <Col className="text-center">
              <img className="subscribe__banner" alt="Banner" />
            </Col>
          </Row>
        )}
        <Row className="m-0 justify-content-center subscribe__signup--padding">
          <div className="subscribe__signup__container">
            <div>
              {branding.headerImageURL === '' ? (
                <div className="subscribe__header__text">
                  <span>{branding.campaignName}</span>
                  <hr className="subscribe__header__divider" />
                </div>
              ) : (
                <div className="subscribe__signup__header m-auto">
                  <img
                    className="subscribe__signup__header__image"
                    alt="Header"
                  />
                </div>
              )}
              <div className="subscribe__signup__content m-auto">
                <div className="subscribe__content__title">
                  <span>{unsubscribeTitleCopy}</span>
                </div>
                <div className="subscribe__content__description">
                  {unsubscribeBodyCopy}
                </div>
              </div>
            </div>
          </div>
        </Row>
        {(isPreview || isCountingDown) && (
          <Row className="m-0 justify-content-center subscribe__redirect--padding">
            <div className="subscribe__redirect__container">
              <Row className="m-0">
                <Col xs={10} className="p-0 pr-2">
                  <span className="subscribe__redirect w-100">
                    {reactStringReplace(
                      branding.unsubscribeRedirectMessage,
                      `{timeRemaining}`,
                      timeRemaining
                    )}
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="text-right p-0 d-flex align-items-center justify-content-center"
                >
                  <Button variant="link" className="p-0" onClick={handleCancel}>
                    {branding.unsubscribeCancelButtonLabel}
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        )}
      </div>
    </>
  );
};

Unsubscribe.propTypes = {
  branding: PropTypes.shape({
    campaignName: PropTypes.string.isRequired,
    emailAddress: PropTypes.string,
    headerImageURL: PropTypes.string,
    logoImageURL: PropTypes.string,
    page: PropTypes.string,
    redirectURL: PropTypes.string.isRequired,
    unsubscribeBodyCopy: PropTypes.string,
    unsubscribeTitleCopy: PropTypes.string,
    unsubscribeRedirectMessage: PropTypes.string.isRequired,
    unsubscribeCancelButtonLabel: PropTypes.string.isRequired,
  }),
};

Unsubscribe.defaultProps = {
  branding: null,
};

export default Unsubscribe;
