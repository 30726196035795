/* eslint no-param-reassign: "off" */

import axios from 'axios';

import * as errors from 'common/errors';
import { mandatory } from 'common/validation';

import { API_TIMEOUTS, apiBaseURL } from 'api/settings';

export default async function getSubscriberPreferences({
  jwt = mandatory('jwt'),
}) {
  const config = {
    url: `${apiBaseURL()}/public/subscriberpreferencescenter`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: {
      'X-EBX-SubscriberPreferencesToken': jwt,
    },
  };

  try {
    const response = await axios(config);
    return response.data.campaigns;
  } catch (error) {
    const apiError = await errors.handleAPIError({
      originalError: error,
      errorLocation: 'api/getSubscriberPreferences',
    });
    throw apiError;
  }
}
