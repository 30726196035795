import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useInterval from 'hooks/useInterval';
import useQuery from 'hooks/useQuery';

import * as string from 'common/string';

import reactStringReplace from 'react-string-replace';
import './Base-Subscribe.css';

const Success = ({ branding }) => {
  let { userEmail } = useParams();
  userEmail = userEmail ?? branding?.emailAddress;

  const queryParams = useQuery();
  const isEmbeddedMode = queryParams.get('embed') !== null;

  const hasRedirectURL =
    branding?.redirectURL && branding.redirectURL !== '' && !isEmbeddedMode;
  const isPreview = branding?.page;
  const [isCountingDown, setCountingDown] = useState(hasRedirectURL);
  const [timeRemainingSecs, setTimeRemainingSecs] = useState(15);

  useInterval(
    // Callback - code to be executed each interval
    () => {
      if (timeRemainingSecs > 0) {
        setTimeRemainingSecs(timeRemainingSecs - 1);
      } else {
        setCountingDown(false);
        handleRedirect();
      }
    },
    // Interval - return null to stop the interval when the countdown reaches zero
    isCountingDown ? 1000 : null
  );

  if (!branding) {
    return null;
  }

  const timeRemaining = () =>
    `${timeRemainingSecs} second${timeRemainingSecs !== 1 ? 's' : ''}`;

  const handleCancel = () => {
    setCountingDown(false);
  };

  const handleRedirect = () => {
    window.location.href = branding.redirectURL;
  };

  const embeddedClass = isEmbeddedMode ? 'embedded' : '';

  return (
    <>
      <div className={`subscribe__page client__specific ${embeddedClass}`}>
        {branding.logoImageURL === '' ? null : (
          <Row className="subscribe__header w-100 d-flex align-items-center m-0">
            <Col className="text-center">
              <img className="subscribe__banner" alt="Banner" />
            </Col>
          </Row>
        )}
        <Row className="m-0 justify-content-center subscribe__signup--padding">
          <div className="subscribe__signup__container">
            <div>
              {branding.headerImageURL === '' ? (
                <div className="subscribe__header__text">
                  <span>{branding.campaignName}</span>
                  <hr className="subscribe__header__divider" />
                </div>
              ) : (
                <div className="subscribe__signup__header m-auto">
                  <img
                    className="subscribe__signup__header__image"
                    alt="Header"
                  />
                </div>
              )}
              <div className="subscribe__signup__content m-auto d-flex align-items-center">
                {isEmbeddedMode && (
                  <>
                    {branding.logoImageURL === '' ? null : (
                      <>
                        <img
                          className="subscribe__banner subscribe__embedded__banner mr-3 mt-2"
                          alt="Banner"
                        />
                      </>
                    )}
                  </>
                )}
                <div className="d-flex flex-wrap align-items-center">
                  <div className="subscribe__content__title w-100">
                    <span>
                      {string.replacePlaceholders(
                        branding.confirmationTitleCopy,
                        branding
                      )}
                    </span>
                  </div>
                  <div className="subscribe__content__description w-100">
                    {reactStringReplace(
                      branding.confirmationBodyCopy,
                      '{emailAddress}',
                      () => (
                        <span className="subscribe__user-email">
                          {userEmail}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        {(isPreview || isCountingDown) && (
          <Row className="m-0 justify-content-center subscribe__redirect--padding">
            <div className="subscribe__redirect__container">
              <Row className="m-0">
                <Col xs={10} className="p-0 pr-2">
                  <span className="subscribe__redirect w-100">
                    {reactStringReplace(
                      branding.confirmationRedirectMessage,
                      `{timeRemaining}`,
                      timeRemaining
                    )}
                  </span>
                </Col>
                <Col
                  xs={2}
                  className="text-right p-0 d-flex align-items-center justify-content-center"
                >
                  <Button variant="link" className="p-0" onClick={handleCancel}>
                    {branding.confirmationCancelButtonLabel}
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        )}
      </div>
    </>
  );
};

Success.propTypes = {
  branding: PropTypes.shape({
    campaignName: PropTypes.string.isRequired,
    emailAddress: PropTypes.string,
    confirmationBodyCopy: PropTypes.string.isRequired,
    confirmationTitleCopy: PropTypes.string.isRequired,
    headerImageURL: PropTypes.string,
    logoImageURL: PropTypes.string,
    page: PropTypes.string,
    redirectURL: PropTypes.string.isRequired,
    confirmationRedirectMessage: PropTypes.string.isRequired,
    confirmationCancelButtonLabel: PropTypes.string.isRequired,
  }),
};

Success.defaultProps = {
  branding: null,
};

export default Success;
