export const API_ERROR_MESSAGES = {
  API_INITIALISATION:
    'Unable to reach Echobox. Please check your internet connection.',
  API_TIMEOUT:
    'Unfortunately, this action timed out. Please refresh the page and try again.',
  API_TERMINATION:
    'Unfortunately, your connection to Echobox was terminated unexpectedly. Please check your internet connection and try again.',
  API_HTTP:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
  API_XHR:
    'Unfortunately, this action timed out. Please retry or reload the page.',
};

export const API_ERROR_TYPES = {
  API_INITIALISATION: 'API_INITIALISATION',
  API_TIMEOUT: 'API_TIMEOUT',
  API_TERMINATION: 'API_TERMINATION',
  API_HTTP: 'API_HTTP',
  API_HTTP_COGNITO: 'API_HTTP_COGNITO',
  API_HTTP_EXTERNAL: 'API_HTTP_EXTERNAL',
  API_XHR: 'API_XHR',
};

export const GOOGLE_FONTS_API_KEY = 'AIzaSyBYAfnXc8YOXiHfEHRwyGfW_h-35-mFefo';

export const NON_API_ERROR_MESSAGES = {
  NON_API_GENERAL:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
};

export const NON_API_ERROR_TYPES = {
  NON_API_GENERAL: 'NON_API_GENERAL',
};

export const PAGES = {
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  PROPERTY_SUBSCRIBE: 'PROPERTY_SUBSCRIBE',
  VERIFY: 'VERIFY',
  SUCCESS: 'SUCCESS',
  MANAGE_PREFERENCES: 'MANAGE_PREFERENCES',
  MANAGE_PREFERENCES_SUCCESS: 'MANAGE_PREFERENCES_SUCCESS',
  ACCESS_PREFERENCES: 'ACCESS_PREFERENCES',
  ACCESS_PREFERENCES_SENT: 'ACCESS_PREFERENCES_SENT',
};

export const REDIRECT_HOME = 'https://www.echobox.com/newsletters-overview';
