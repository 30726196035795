import PropTypes from 'prop-types';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

function UpdatePreferences({
  branding,
  handleCancel,
  handleUpdate,
  isBusy,
  isCancelDisabled,
  isUpdateDisabled,
}) {
  return (
    <Row className="subscribe__footer m-0 manage__preferences__footer">
      <Col sm={12} md={6}>
        <Button
          className="subscribe__footer__button ebx-btn-lg"
          type="button"
          disabled={isUpdateDisabled}
          onClick={handleUpdate}
        >
          {!isBusy ? (
            branding.updatePreferencesButtonText
          ) : (
            <div>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
            </div>
          )}
        </Button>
        <Button
          className="p-0 m-0 cancel-all-subscriptions primary-font"
          variant="link"
          disabled={isCancelDisabled}
          onClick={handleCancel}
        >
          {branding.cancelAllSubscriptionsButtonText}
        </Button>
      </Col>
      <Col sm={12} md={6} className="preferences-privacy-policy">
        {branding.helperText}
        {branding.propertyPrivacyPolicyEnabled && (
          <>
            <span> - </span>
            <a href={`${branding.propertyPrivacyPolicyLink}`}>
              {branding.propertyPrivacyPolicyText || 'Privacy Policy'}
            </a>
          </>
        )}
      </Col>
    </Row>
  );
}

UpdatePreferences.propTypes = {
  branding: PropTypes.shape({
    cancelAllSubscriptionsButtonText: PropTypes.string.isRequired,
    updatePreferencesButtonText: PropTypes.string.isRequired,
    helperText: PropTypes.string.isRequired,
    propertyPrivacyPolicyEnabled: PropTypes.bool.isRequired,
    propertyPrivacyPolicyLink: PropTypes.string.isRequired,
    propertyPrivacyPolicyText: PropTypes.string.isRequired,
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isCancelDisabled: PropTypes.bool.isRequired,
  isUpdateDisabled: PropTypes.bool.isRequired,
};

export default UpdatePreferences;
