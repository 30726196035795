/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { getDataFileURL, getHostname };

const getDataFileURL = entityKey => {
  const hostname = getHostname();
  switch (hostname) {
    case 'dev':
      return `http://localhost:9000/${entityKey}/branding.json`;
    case 'stage':
      return `https://stage.campaign-list.com/${entityKey}/branding.json`;
    default:
      return `https://www.campaign-list.com/${entityKey}/branding.json`;
  }
};

const getHostname = () => {
  const host = window.location.hostname.split('.')[0];
  switch (host) {
    case 'stage':
      return 'stage';
    case 'localhost':
    case 'local.echobox.com':
      return 'dev';
    default:
      return 'live';
  }
};
