/* eslint react/destructuring-assignment: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, FormControl, Row, Spinner } from 'react-bootstrap';

import * as API from 'api/api';
import * as errors from 'common/errors';
import * as tracker from 'common/tracker';
import * as validator from 'email-validator';
import useInterval from 'hooks/useInterval';
import useQuery from 'hooks/useQuery';

import { ReactComponent as Tick } from 'assets/svg/tick.svg';
import CampaignList from 'components/subscribe/property/CampaignList';
import PageHeader from 'components/subscribe/property/PageHeader';

import { PropertyBranding } from 'common/types';

import '../Base-Subscribe.css';
import './Embed-Subscribe.css';

const prepareName = (name: string) =>
  name.trim() === '' ? undefined : name.trim();

interface NameProps {
  disabled: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
  value: string;
}

const Name = ({ disabled, label, onChange, onKeyPress, value }: NameProps) => (
  <div className="d-block w-100 subscribe__property__content__firstname">
    <div className="subscribe__property__content__label">{label}</div>
    <FormControl
      className="subscribe__property__content__input"
      type="text"
      value={value}
      disabled={disabled}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  </div>
);

interface SignupButtonProps {
  disabled: boolean;
  isBusy: boolean;
  label: string;
  onClick: () => {};
}

const SignupButton = ({
  disabled,
  isBusy,
  label,
  onClick,
}: SignupButtonProps) => (
  <Button
    className="subscribe__content__button ebx-btn-lg"
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    {!isBusy ? (
      label
    ) : (
      <div>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-2"
        />
      </div>
    )}
  </Button>
);

interface PropertySubscribeProps {
  branding: PropertyBranding;
}

const PropertySubscribe = (props: PropertySubscribeProps) => {
  const { branding } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isSigningUp, setSigningUp] = useState(false);
  const [isSignupComplete, setSignupComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isCountingDown, setCountingDown] = useState(false);
  const [timeRemainingSecs, setTimeRemainingSecs] = useState(3);

  const pageInstanceId = useRef(0);

  const queryParams = useQuery();
  const isEmbeddedMode = queryParams.get('embed') !== null;

  useEffect(() => {
    /* Generate random nine-digit number starting with non-zero digit */
    pageInstanceId.current = Math.floor(100000000 + Math.random() * 900000000);
    tracker.track({
      eventName: 'Display Subscription Page',
      trackingParams: {
        'Subscription page displayed to reader': window.location.href,
        '# campaigns offered': branding.orderedCampaigns.length,
        'Page instance ID': pageInstanceId.current,
        'Subscription Page': 'Property',
      },
    });
  }, []);

  useInterval(
    // Callback - code to be executed each interval
    () => {
      if (timeRemainingSecs > 0) {
        setTimeRemainingSecs(timeRemainingSecs - 1);
      } else {
        setCountingDown(false);
        handleRedirect();
      }
    },
    // Interval - return null to stop the interval when the countdown reaches zero
    isCountingDown ? 1000 : null
  );

  const initialSelectedCampaigns = branding.orderedCampaigns.map(
    campaignURNAndName => ({
      ...campaignURNAndName,
      isSelected: false,
    })
  );

  const [selectedCampaigns, setSelectedCampaigns] = useState(
    initialSelectedCampaigns
  );

  let selectedCampaignCount = 0;
  selectedCampaigns.forEach(campaignURNAndName => {
    selectedCampaignCount += campaignURNAndName.isSelected ? 1 : 0;
  });

  const validEmail = validator.validate(emailAddress.trim());

  const disableButton = useMemo(
    () => isSigningUp || selectedCampaignCount === 0 || !validEmail,
    [firstName, isSigningUp, lastName, selectedCampaignCount, validEmail]
  );

  if (!branding) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
    setErrorMessage(null);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    setErrorMessage(null);
  };

  const handleKeypress = (event: React.KeyboardEvent) => {
    setErrorMessage(null);
    if (event.charCode === 13 && !disableButton) {
      handleSubmit();
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setErrorMessage(null);
  };

  const handleRedirect = () => {
    const hasRedirectURL = branding?.redirectURL && branding.redirectURL !== '';
    if (hasRedirectURL) {
      window.location.href = branding.redirectURL;
    }
  };

  const handleSubmit = async () => {
    setSigningUp(true);
    const campaignsToSubscribe = selectedCampaigns.filter(
      campaign => campaign.isSelected
    );
    const campaignURNs = campaignsToSubscribe
      .reduce(
        (prevValue, currValue) => `${prevValue}${currValue.campaignURN},`,
        ''
      )
      .slice(0, -1);
    try {
      await API.postSubscribe({
        campaignKey: campaignURNs,
        emailAddress: emailAddress.trim(),
        firstName: prepareName(firstName),
        lastName: prepareName(lastName),
      });
      tracker.track({
        eventName: 'Reader Subscribe',
        trackingParams: {
          '# campaigns offered': branding.orderedCampaigns.length,
          '# campaigns subscribed to': campaignURNs.split(',').length,
          'Page instance ID': pageInstanceId.current,
          'Subscription Page': 'Property',
        },
      });
      setSignupComplete(true);
      setSigningUp(false);
      setCountingDown(true);
    } catch (error) {
      console.log(error);
      let errorMsg = errors.getErrorMessage(error);
      if (errorMsg === 'Internal Server Error') {
        errorMsg = 'Unable to subscribe, please try again';
      }
      if (errorMsg.includes('action could not be completed')) {
        errorMsg = 'Please check you have entered a valid email address';
      }
      setErrorMessage(errorMsg);
      setSigningUp(false);
    }
  };

  const handleSelection = (value: string) => {
    const newSelectedCampaigns = selectedCampaigns.map(campaign => {
      if (campaign.campaignURN === value) {
        const modifiedCampaign = {
          ...campaign,
          isSelected: !campaign.isSelected,
        };
        return modifiedCampaign;
      }
      return campaign;
    });

    setSelectedCampaigns(newSelectedCampaigns);
  };

  const embeddedClass = isEmbeddedMode ? 'property-embedded' : '';

  return (
    <>
      <div
        className={`subscribe__page client__specific d-flex flex-column ${embeddedClass}`}
      >
        <PageHeader branding={branding} />

        <CampaignList
          campaignList={selectedCampaigns}
          handleSelection={handleSelection}
        />

        {isSignupComplete ? (
          <Row className="subscribe__footer d-flex flex-column align-items-center m-0">
            <div className="subscribe__property__confirmation">
              <Tick />
              <span className="p-2">{branding.confirmationTitleCopy}</span>
            </div>
            <div className="subscribe__content__privacy mt-2">
              {branding.confirmationBodyCopy}
            </div>
          </Row>
        ) : (
          <Row className="subscribe__footer align-items-center justify-content-center m-0">
            <div className="subscribe__variable__width">
              {branding?.isFirstNameLastNameEnabled && (
                <div className="subscribe__signup__content__container d-flex flex-column flex-md-row">
                  <Name
                    disabled={isSigningUp}
                    label={branding?.firstNameLabel ?? 'First name'}
                    onChange={handleFirstNameChange}
                    onKeyPress={handleKeypress}
                    value={firstName}
                  />
                  <Name
                    disabled={isSigningUp}
                    label={branding?.lastNameLabel ?? 'Last name'}
                    onChange={handleLastNameChange}
                    onKeyPress={handleKeypress}
                    value={lastName}
                  />
                </div>
              )}
              <div className="subscribe__property__content__label">
                {branding.emailInputLabel} <span className="mandatory">*</span>
              </div>
              <div className="fit-content subscribe__email d-flex flex-column flex-md-row">
                <FormControl
                  className="subscribe__property__content__input"
                  type="email"
                  value={emailAddress}
                  disabled={isSigningUp}
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                />
                <SignupButton
                  disabled={disableButton}
                  isBusy={isSigningUp}
                  label={branding?.signupButtonLabel ?? 'Sign Up'}
                  onClick={handleSubmit}
                />
              </div>
              {errorMessage && (
                <div className="subscribe__content__error">{errorMessage}</div>
              )}
              <div className="subscribe__property__content__helper">
                {branding.helperText}
                {branding.propertyPrivacyPolicyEnabled && (
                  <>
                    <span> - </span>
                    <a href={`${branding.propertyPrivacyPolicyLink}`}>
                      {branding.propertyPrivacyPolicyText || 'Privacy Policy'}
                    </a>
                  </>
                )}
              </div>
            </div>
          </Row>
        )}
      </div>
    </>
  );
};

export default PropertySubscribe;
