/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import * as fonts from 'common/fonts';

export { setBranding };

// Backwards compatibility
const backwardsCompatibleEntries = obj => {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i); // preallocate the Array

  while ((i -= 1)) resArray[i] = [ownProps[i], obj[ownProps[i]]];
  return resArray;
};

const setBranding = branding => {
  const root = document.documentElement;
  // Map CSS variable names to the corresponding keys in the JSON data file
  const map = {
    '--accent-colour': 'accentColour',
    '--primary-font': 'titleFont',
    '--secondary-font': 'bodyFont',
    '--logo-image': 'logoImageURL',
    '--header-image': 'headerImageURL',
  };
  // Backwards compatibility
  if (!Object.entries) {
    Object.entries = backwardsCompatibleEntries;
  }
  // Process each entry in the map
  for (const [cssVariable, objectKey] of Object.entries(map)) {
    // Retrieve the relevant value from the branding data file
    let cssValue = branding[objectKey];
    if (cssValue) {
      // Perform any necessary formatting depending on the parameter type
      switch (cssVariable) {
        // Add hash symbol to colour hex codes
        case '--accent-colour':
          cssValue = `#${cssValue}`;
          break;
        // Load any required fonts and add a backup font name
        case '--primary-font':
        case '--secondary-font':
          fonts.loadGoogleFont(cssValue);
          cssValue = `${cssValue}, serif`;
          break;
        // Add url(...) to image paths
        case '--logo-image':
        case '--header-image':
          cssValue = `url('${cssValue}')`;
          break;
        default:
      }
      // Update the CSS variable with the new value
      root.style.setProperty(cssVariable, cssValue, 'important');
    }
  }
};
