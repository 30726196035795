/* eslint no-use-before-define: "off" */

import axios from 'axios';

import { API_TIMEOUTS } from 'api/settings';
import { GOOGLE_FONTS_API_KEY } from 'common/constants';

export { getGoogleFonts, loadGoogleFont };

const getGoogleFonts = async () => {
  const config = {
    url: `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONTS_API_KEY}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
  };

  const response = await axios(config);
  return response?.data?.items;
};

const loadGoogleFont = family => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  const css = `@import url('https://fonts.googleapis.com/css?family=${family.replace(
    /\s/gi,
    '+'
  )}');`;
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);
};
