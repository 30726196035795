import { Button } from 'react-bootstrap';

import './ErrorPage.css';

const handleBack = () => {
  window.history.back();
};

const handleRetry = () => {
  window.location.reload();
};

const ErrorPage = () => (
  <div className="d-flex justify-content-center error-page__page-height flex-wrap">
    <div className="error-page__card">
      <div className="ebx-card mx-auto">
        <div className="font-weight-bold pb-4">Something went wrong</div>
        <div>
          <p>Unfortunately something went wrong with your request.</p>
          <p>
            If the problem occurred when loading the page, click the{' '}
            <strong>Try again</strong> button below to see if that helps, or if
            the problem occurred after clicking Submit, press{' '}
            <strong>Go back</strong> to return to the previous page.
          </p>
        </div>
        <div className="pt-4">
          <Button
            className="ebx-btn-lg mr-2"
            type="button"
            onClick={handleRetry}
          >
            Try again
          </Button>
          <Button className="ebx-btn-lg" type="button" onClick={handleBack}>
            Go back
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
