/* eslint no-underscore-dangle: ["error", { "allow": ["__serialized__"] }] */
/* eslint no-param-reassign: "off" */
/* eslint react/destructuring-assignment: "off" */

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Routing from 'containers/Routing';
import { createRoot } from 'react-dom/client';

import * as environment from 'common/environment';
import * as errors from 'common/errors';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'assets/css/ebx-ui-kit.css';
import 'assets/css/index.css';

const hostname = environment.getHostname();

/* Sentry configuration */
const dsns = {
  live: 'https://24559e050c06406cbeaa215dc5642b8d@o155242.ingest.sentry.io/5998335',
  stage:
    'https://9586510bf4d9407a804aea56203e7ff3@o155242.ingest.sentry.io/5998331',
  // dev: 'https://18ed0c983b1a4e6d8d6cced606d6b90a@o155242.ingest.sentry.io/5998328',
};
const rates = {
  live: 0.001,
  review: 0.01,
  stage: 0.01,
  local: 0.01,
};
const dsn = dsns[hostname];

if (typeof Sentry !== 'undefined' && typeof dsn !== 'undefined') {
  Sentry.init({
    dsn,
    environment: window.location.host.split('.')[0],
    integrations: [
      new BrowserTracing({
        beforeNavigate: context => ({
          ...context,
          name: window.location.pathname.replace(/\d+/g, '{id}'),
        }),
        tracingOrigins: [
          'campaignapi.service.echobox.com',
          'campaignapi-stage.service.echobox.com',
          'coreapi.service.echobox.com',
          'coreapi-stage.service.echobox.com',
        ],
      }),
    ],
    tracesSampleRate: rates[hostname],
    beforeSendTransaction(transaction) {
      const cleanedTransaction = errors.cleanUserDetails(
        transaction.request.url,
        transaction.transaction
      );
      transaction.request.url = cleanedTransaction.cleanedURL;
      transaction.transaction = cleanedTransaction.cleanedTransaction;
    },
    beforeSend(event, hint) {
      // Get rid of sender details in the url
      const { cleanedURL } = errors.cleanUserDetails(event.request.url);
      event.request.url = cleanedURL;

      // Ignore non-error promise rejections
      if (
        event &&
        event.__serialized__?.error &&
        event.__serialized__?.status
      ) {
        return null;
      }
      // Handle non-error exceptions
      const ignoredError = errors.isIgnoredError(event, hint);
      if (
        event &&
        event.message &&
        event.message.startsWith('Non-Error exception captured') &&
        hint &&
        hint.originalException?.error &&
        !ignoredError
      ) {
        Sentry.withScope(scope => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }
      if (!ignoredError) {
        return event;
      }
      return null;
    },
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Routing />);
