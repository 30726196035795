import PropTypes from 'prop-types';

import Checkbox from 'components/subscribe/property/Checkbox';

import './Campaign.css';

const Campaign = ({
  campaignURN,
  campaignName,
  isSelected,
  handleSelection,
}) => {
  const handleChange = id => {
    handleSelection(id);
  };

  return (
    <Checkbox
      className={`campaign__container__${isSelected ? 'enabled' : 'disabled'}`}
      handleChange={handleChange}
      id={campaignURN}
      isSelected={isSelected}
      labelName={campaignName}
    />
  );
};

Campaign.propTypes = {
  campaignURN: PropTypes.string,
  campaignName: PropTypes.string,
  isSelected: PropTypes.bool,
  handleSelection: PropTypes.func,
};

Campaign.defaultProps = {
  campaignURN: null,
  campaignName: null,
  isSelected: null,
  handleSelection: null,
};

export default Campaign;
