/* eslint import/prefer-default-export: "off" */

export {
  cloneError,
  cloneObject,
  isDefined,
  isError,
  isErrorLike,
  isInIframe,
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
};

function cloneError(error) {
  /**
   * JSON.stringify-ing an Error object doesn't work as many of its properties are
   * not enumerable, so this method explicitly stringifies the relevant keys
   */
  if (isNullOrUndefined(error)) {
    return error;
  }
  try {
    return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
  } catch (e) {
    return error;
  }
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isDefined(value) {
  return !isUndefined(value);
}

function isError(error) {
  switch ({}.toString.call(error)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return error instanceof Error;
  }
}

function isErrorLike(error) {
  return (
    !isError(error) &&
    typeof error === 'object' &&
    isDefined(error) &&
    isDefined(error.message)
  );
}

function isNull(value) {
  return value === null;
}

function isNullOrUndefined(value) {
  return isNull(value) || isUndefined(value);
}

function isRunningTests() {
  return process.env.NODE_ENV === 'test';
}

function isUndefined(value) {
  return value === undefined || typeof value === 'undefined';
}
