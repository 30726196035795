import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

function AccessPreferencesSent({ branding }) {
  return (
    <Row className="m-0 justify-content-center subscribe__signup--padding">
      <div className="access__preferences__container">
        <Row className="subscribe__header access__preferences__header w-100 d-flex align-items-center m-0">
          <Col className="text-center">
            <img className="subscribe__banner" alt="Banner" />
          </Col>
        </Row>
        <Row className="subscribe__header access__preferences__footer w-100 d-flex align-items-center m-0">
          <div className="subscribe__content__title w-100">
            {branding.accessEmailSentHostedPageTitleCopy}
          </div>
          <div className="subscribe__content__description w-100 pb-0">
            <p className="mb-0">{branding.accessEmailSentHostedPageBodyCopy}</p>
          </div>
        </Row>
      </div>
    </Row>
  );
}

AccessPreferencesSent.propTypes = {
  branding: PropTypes.shape({
    logoImageURL: PropTypes.string,
    accessEmailSentHostedPageBodyCopy: PropTypes.string,
    accessEmailSentHostedPageTitleCopy: PropTypes.string,
  }),
};

AccessPreferencesSent.defaultProps = {
  branding: null,
};

export default AccessPreferencesSent;
