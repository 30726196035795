/* eslint no-use-before-define: "off" */
/* global _LTracker */

import Moment from 'moment';

import * as errors from 'common/errors';
import { stripKeys } from 'common/object';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isRunningTests,
} from 'common/utility';

export { error, info };

let version = '';
try {
  version = sessionStorage.getItem('currentVersion');
} catch {
  //
}

function error(args) {
  logger({ ...args, severity: 'error' });
}

function info(args) {
  if (typeof args === 'undefined' || isNull(args)) {
    // Ignore request
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'info' });
  } else {
    logger({ ...args, severity: 'info' });
  }
}
function logger(rawArgs) {
  const args = {};

  // Set dev mode to true if we are running locally
  // The catch block handles the case where we are running in a Jest environment
  let dev;
  try {
    dev = window.location.hostname === 'localhost';
  } catch (e) {
    dev = true;
  }

  // Set parameter defaults
  if (isDefined(rawArgs.log) && typeof rawArgs.log === 'boolean') {
    args.log = rawArgs.log;
  } else {
    args.log = true;
  }
  if (isDefined(rawArgs.event) && typeof rawArgs.event === 'string') {
    args.event = rawArgs.event;
  } else {
    throw new ReferenceError('event is undefined');
  }
  if (isDefined(rawArgs.properties) && typeof rawArgs.properties === 'object') {
    args.properties = rawArgs.properties;
  } else {
    args.properties = {};
  }
  if (isDefined(rawArgs.severity) && typeof rawArgs.severity === 'string') {
    args.severity = rawArgs.severity;
  } else {
    args.severity = 'info';
  }

  // Work out if we actually need to log anything
  let logToConsole = dev;
  if (!logToConsole) {
    try {
      logToConsole = sessionStorage.getItem('logToConsole');
    } catch {
      //
    }
  }
  if (args.severity === 'info' && !logToConsole) {
    return;
  }

  // Add error details
  if (isDefined(rawArgs.error)) {
    // Add error details if an error object has been passed
    if (rawArgs.error instanceof Error) {
      args.properties.Error = JSON.parse(errors.stringifyError(rawArgs.error));
      const errorMessage = errors.getErrorMessage(rawArgs.error);
      if (errorMessage !== '') {
        args.properties.ErrorMessage = errorMessage;
      }
      const errorStatus = errors.getErrorStatus(rawArgs.error);
      if (!isNull(args.errorStatus)) {
        args.properties.ErrorStatus = errorStatus;
      }
    }

    // Add error message and stacktrace if an errorEvent object has been passed
    if (rawArgs.error instanceof ErrorEvent) {
      let message;
      let stack;
      if (!isNullOrUndefined(rawArgs.error.error)) {
        message = rawArgs.error.error.message;
        stack = rawArgs.error.error.stack;
      } else {
        message = rawArgs.error.message;
        stack = rawArgs.error.stack;
      }
      if (message !== '') {
        args.properties.ErrorMessage = message;
      }
      if (stack !== '') {
        args.properties.Stack = stack;
      }
    }

    // Add error details for non-"real" errors
    if (
      !(rawArgs.error instanceof Error) &&
      !(rawArgs.error instanceof ErrorEvent)
    ) {
      args.properties.Error = rawArgs.error;
    }

    // Make error type a top-level property and remove it from the error details
    if (args.properties?.Error?.type) {
      args.properties.ErrorType = args.properties.Error.type;
      delete args.properties.Error.type;
    }

    // Strip credentials from the error details
    args.properties.Error = stripKeys(args.properties.Error);
  }

  // Work out if we need to write to logging and/or tracking systems
  const sendToLogger =
    args.log &&
    typeof _LTracker !== 'undefined' &&
    !dev &&
    (args.severity === 'error' || args.severity === 'track');

  // Add version number to reported data
  args.properties.HV = version;

  // Add user agent to reported data
  if (window) {
    args.properties.UserAgent = window.navigator.userAgent;
  }

  // Stringify any non-essential elements to avoid creating unnecessary indexed fields in Loggly
  args.properties = errors.stringifyReport(args.properties);

  // Send event details to logging system
  if (sendToLogger) {
    try {
      const EventType = args.event;
      _LTracker.push({
        EventSource: 'Newsletters-Subscriptions-UI',
        EventType,
        Kubernetes: {
          node_name: window?.EBX?.env?.NODE_NAME,
          pod_name: window?.EBX?.env?.POD_NAME,
          pod_namespace: window?.EBX?.env?.POD_NAMESPACE,
        },
        ...args.properties,
      });
    } catch (e) {
      console.log(e);
    }
  }

  // Write event details to console when running locally
  let enableLiveLogging = false;
  let enableTestLogging = false;
  try {
    enableLiveLogging = sessionStorage.getItem('enableLiveLogging');
    enableTestLogging = sessionStorage.getItem('enableTestLogging');
  } catch {
    //
  }
  if (
    args.log &&
    (dev || typeof _LTracker === 'undefined' || !isNull(enableLiveLogging)) &&
    (!isRunningTests() || !isNull(enableTestLogging))
  ) {
    if (args.severity === 'info') {
      console.log(`*** ${Moment().format('HH:mm:ss.SSS')} ${args.event}`);
    } else {
      console[args.severity](`Event: ${args.event}`);
      console[args.severity](
        `Properties: ${JSON.stringify(args.properties, null, 2)}`
      );
    }
  }
}
