import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import LogoImage from 'components/subscribe/property/LogoImage';

import useInterval from 'hooks/useInterval';

function InvalidLink({ branding, emailAddress, propertyURN, campaignURN }) {
  const [isCountingDown, setCountingDown] = useState(emailAddress !== null);
  const [timeRemainingSecs, setTimeRemainingSecs] = useState(10);

  useInterval(
    () => {
      if (timeRemainingSecs > 0) {
        setTimeRemainingSecs(timeRemainingSecs - 1);
      } else {
        setCountingDown(false);
        window.location.href = `/accesspreferences/${propertyURN}?email=${emailAddress}&campaignURN=${campaignURN}`;
      }
    },
    // Interval - return null to stop the interval when the countdown reaches zero
    isCountingDown ? 1000 : null
  );

  const handleCancel = () => {
    setCountingDown(false);
  };

  if (emailAddress === null) {
    return (
      <>
        <LogoImage logoImageURL={branding.logoImageURL} />
        <div className="text-center p-4 primary-font">
          The link may have expired or is invalid. Please request a new link by
          clicking the &quot;Manage your preferences&quot; link from a recent
          email.
        </div>
      </>
    );
  }

  return (
    <>
      <LogoImage logoImageURL={branding.logoImageURL} />
      <div className="text-center p-4 primary-font">
        The link may have expired or is invalid. You will be redirected to
        request another link.
      </div>
      {isCountingDown && (
        <Row className="m-0 justify-content-center subscribe__redirect--padding">
          <div className="subscribe__redirect__container">
            <Row className="m-0">
              <Col xs={10} className="p-0 pr-2">
                <span className="subscribe__redirect w-100 primary-font">
                  Redirecting in {timeRemainingSecs}
                </span>
              </Col>
              <Col
                xs={2}
                className="text-right p-0 d-flex align-items-center justify-content-center"
              >
                <Button
                  variant="link"
                  className="p-0 primary-font"
                  onClick={handleCancel}
                >
                  {branding.confirmationCancelButtonLabel}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      )}
    </>
  );
}

InvalidLink.propTypes = {
  branding: PropTypes.shape({
    logoImageURL: PropTypes.string,
    confirmationCancelButtonLabel: PropTypes.string,
  }).isRequired,
  emailAddress: PropTypes.string,
  propertyURN: PropTypes.string.isRequired,
  campaignURN: PropTypes.string.isRequired,
};

InvalidLink.defaultProps = {
  emailAddress: null,
};

export default InvalidLink;
